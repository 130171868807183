import { FetchClient } from "@/core/client/fetch_client"
import { StatusCode } from "@/core/generated/api/enums/status_code"
import { ResponseModel } from "@/core/generated/api/models/response_model"
import { ResponseSerializer } from "@/core/generated/api/response_serializer"
import { getService } from "hooks/useStartup"
import { useEffect, useState } from "react"
import { LangArgs } from "../models/args/lang_args"

export function useCVRoles(
  cvId: string | null,
  userId: string | null,
  args: LangArgs = new LangArgs(),
) {
  const [data, setData] = useState<Record<string, string[]>>({})
  const [loading, setLoading] = useState<boolean>(false)
  const [code, setCode] = useState<number>(StatusCode.Ok)
  const [message, setMessage] = useState<string>("")

  const client: FetchClient = getService(FetchClient)

  useEffect(() => {
    if (cvId && userId) {
      fetchCVRoles()
    }
  }, [cvId, userId, args.lang])

  const fetchCVRoles = async () => {
    setLoading(true)
    let response: ResponseModel | null = null

    try {
      response = await client.getWithParamsAsync(
        `/flowcase/cvs/${cvId}/${userId}/roles`,
        args,
      )
      if (response !== null) {
        const model: Record<string, string[]> =
          ResponseSerializer.deserialize<Record<string, string[]>>(response)
        if (model !== null) {
          setData(model)
          setCode(response.code)
        } else {
          setCode(StatusCode.NoContent)
        }
      } else {
        setCode(StatusCode.BadRequest)
      }
    } catch (error) {
      console.error("Failed to fetch cv roles", error)
      setCode(StatusCode.BadRequest)
    } finally {
      setMessage(response?.message)
      setLoading(false)
    }
  }

  return { data, loading, code, message }
}
