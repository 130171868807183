import { IField } from "@/features/fields/types"
import { FieldTypeIcons } from "@/features/fields/utils/constants"
import PopoverGeneric from "@/shared/components/fields/PopoverGeneric"
import { IColumn, ITable } from "@/types/tables"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Table } from "antd"
import { ColumnsType, TablePaginationConfig } from "antd/es/table"
import { ColumnFilterItem } from "antd/lib/table/interface"
import Image from "next/image"
import { useEffect, useReducer, useState } from "react"
import { fieldsRegistry } from "../fields/registry"
import { Cell } from "./Cell"
import { Header } from "./Header"
import { useSortableData } from "./hooks/useSortableData"
import { actions } from "./utils/actions"
import { addColumn, updateTable } from "./utils/api"
import { ITableState, reducer } from "./utils/reducer"
import { faCalendarCircleExclamation } from "@awesome.me/kit-44b29310a6/icons/classic/regular"

interface ITableCustom {
  table: ITable
  data: any[]
  fields: IField[]
  staticColumns?: ColumnsType<any>
  filter?: {
    filterableColumns?: string[]
    getFilterItems?(column: IColumn): ColumnFilterItem[]
    onFilter?(column: IColumn): (value: any, record: any) => boolean
  }
  sorting?: {
    sortableColumns?: string[]
    onSort?(column: IColumn): ((a: any, b: any) => number) | null
  }
  loading?: boolean
  onRowClick?(record: any): void
  pagination?: TablePaginationConfig
}

const initialState: ITableState = {
  defaultsort: {
    datatype: "",
    dataindex: "",
    order: "asc",
  },
  showPopover: false,
  filters: [],
  sorter: {
    datatype: "",
    datandex: "",
    order: "asc",
  },
}

export const TableCustom = ({
  table,
  data,
  fields,
  staticColumns,
  filter,
  sorting,
  loading,
  onRowClick,
}: ITableCustom) => {
  const [state, dispatch] = useReducer(reducer, { ...initialState, ...table })
  const sortedData = useSortableData({ data, sorter: state.defaultsort })

  const initialPageSize = 20

  const [pagination, setPagination] = useState<TablePaginationConfig>({
    pageSize: initialPageSize,
    total: data?.length ?? 0,
    showSizeChanger: true,
  })

  useEffect(() => {
    dispatch({ type: actions.UPDATE_TABLE, payload: table })
  }, [table])

  const handlePageSizeChange = (current: number, pageSize: number) => {
    setPagination({ ...pagination, pageSize })
  }

  const onAddColumn = async (column: IColumn) => {
    dispatch({ type: actions.ADD_COLUMN, payload: { column } })
    const createdColumn = await addColumn(state.uid, column)
    dispatch({
      type: actions.UPDATE_COLUMN,
      payload: { column: createdColumn },
    })
  }

  const onFilterChange = async (filters) => {
    const { name, defaultsort } = table
    const parsedFilters = Object.entries(filters).reduce(
      (acc, [key, value]) => {
        if (value) {
          const [trimmedKey] = key.split("-")
          acc[trimmedKey] = value
        }
        return acc
      },
      {},
    )
    dispatch({
      type: actions.UPDATE_FILTER,
      payload: { filters: parsedFilters },
    })
    await updateTable(state.uid, { name, defaultsort, filters: parsedFilters })
  }

  const FieldsDropdown = () => {
    const parsedFields = fields.map((f) => ({
      key: f.key,
      title: f.label,
      icon:
        fieldsRegistry.find((field) => field.type === f.type)?.icon ??
        FieldTypeIcons[f.type],
      onClick: () =>
        onAddColumn({
          title: f.label,
          dataindex: f.key,
          datatype: f.type,
          key: `${f.key}-${state.columns.length}`,
          field: f.uid,
          position: state.columns.length,
        }),
    }))

    return (
      <PopoverGeneric button={<div className="text-2xl">+</div>}>
        <div className="w-48 space-y-2 rounded-md p-2 font-normal shadow-lg">
          <div>
            <h5 className="mb-1 border-b pb-1 text-xs font-medium">Columns</h5>
            <div className="flex flex-col">
              {(parsedFields ?? []).map((f, i) => (
                <button
                  key={f.key}
                  onClick={f.onClick}
                  className="flex items-center gap-2 truncate rounded-md px-2 py-1 hover:bg-neutral-100"
                >
                  {f.key.startsWith("cvp") ? (
                    <Image
                      src="/icons/flowcase.svg"
                      width={14}
                      height={14}
                      alt="flowcase-logo"
                    />
                  ) : (
                    <FontAwesomeIcon icon={f.icon} />
                  )}
                  <span>{f.title}</span>
                </button>
              ))}
            </div>
          </div>
          <div>
            <h5 className="mb-1 border-b pb-1 text-xs font-medium">Advanced</h5>
            <div className="flex flex-col">
              <button
                onClick={() =>
                  onAddColumn({
                    title: "Days remaining",
                    datatype: "days_remaining",
                    dataindex: `days_remaining-${state.columns.length}`,
                    key: `days_remaining-${state.columns.length}`,
                    position: state.columns.length,
                    daysremainingdataindex: undefined,
                  })
                }
                className="flex items-center gap-2 rounded-md px-2 py-1 hover:bg-neutral-100"
              >
                <FontAwesomeIcon icon={faCalendarCircleExclamation} />
                <span>Days remaining</span>
              </button>
            </div>
          </div>
        </div>
      </PopoverGeneric>
    )
  }

  const customColumns: ColumnsType<any> = state.columns.map(
    (column: IColumn) => {
      const { key, dataindex, datatype } = column
      return {
        title: () => (
          <Header column={column} state={state} dispatch={dispatch} />
        ),
        render: (value, record) => (
          <Cell value={value} record={record} column={column} />
        ),
        dataIndex: dataindex.split("."),
        sorter: sorting?.sortableColumns?.includes(datatype)
          ? sorting?.onSort?.(column)
          : null,
        sortIcon: () => <></>,
        sortOrder:
          state.defaultsort?.dataindex === dataindex
            ? state.defaultsort?.order === "asc"
              ? "ascend"
              : "descend"
            : null,
        filters: filter?.filterableColumns?.includes(datatype)
          ? filter.getFilterItems?.(column)
          : null,
        filteredValue: state.filters?.[dataindex] ?? [],
        onFilter: filter.onFilter?.(column),
        key,
      }
    },
  )

  const columns: ColumnsType<any> = [
    ...customColumns,
    ...(staticColumns ?? []),
    {
      key: "add-column",
      title: () => <FieldsDropdown />,
    },
  ]

  return (
    <Table
      dataSource={sortedData}
      columns={columns}
      rowKey={"uid"}
      loading={loading}
      rowClassName={onRowClick ? "cursor-pointer group" : null}
      onRow={(record) => ({
        onClick: () => onRowClick?.(record),
      })}
      showSorterTooltip={false}
      onChange={(pagination, filters, sorter) => {
        onFilterChange(filters)
      }}
      scroll={{ x: "max-content" }}
      pagination={{
        ...pagination,
        onShowSizeChange: handlePageSizeChange,
      }}
    />
  )
}
