import { Skeleton } from "@/shared/components/skeletons/Skeleton"
import { ICell } from "../../Cell"
import { useUser } from "@/modules/flowcase/hooks/useUser"
import { StatusCode } from "@/core/generated/api/enums/status_code"

export const DepartmentCell = (props: ICell) => {
  const cvpId = props.record?.cvp_id
  const {
    data: user,
    loading: isLoading,
    code,
  } = useUser(props.record?.type === "INTERNAL" ? cvpId : null)

  if ((code !== StatusCode.Ok || !user) && !isLoading) {
    return <p className="text-gray-200">No department</p>
  }

  if (isLoading) {
    return <Skeleton className="h-4 w-12" />
  }

  return user?.office_name
}
